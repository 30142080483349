import $ from "jquery";

import "./jstree.scss";

type CollapsibleCheckboxTreeOptions = {
    checkParents: boolean;
    checkChildren: boolean;
    uncheckChildren: boolean;
};

const defaults: CollapsibleCheckboxTreeOptions = {
    checkParents: true, // When checking a box, all parents are checked
    checkChildren: false, // When checking a box, all children are checked
    uncheckChildren: true, // When unchecking a box, all children are unchecked
};

const collapsibleCheckboxTree = function (
    elm: HTMLElement,
    options?: Partial<CollapsibleCheckboxTreeOptions>
): void {
    const settings = { ...defaults, ...options };

    // Hide all except top level
    $("ul", elm).addClass("hide");

    // Check parents if necessary
    if (settings.checkParents) {
        $("input:checked").parents("li").find('input[type="checkbox"]:first').prop("checked", true);
    }

    // Check children if necessary
    if (settings.checkChildren) {
        $("input:checked").parent("li").find('input[type="checkbox"]').prop("checked", true);
    }

    // Show checked and immediate children of checked
    $("li:has(input:checked) > ul", elm).removeClass("hide");

    // Add tree links
    $("li", elm).prepend("<span>&nbsp;</span>");
    $("li:has(> ul:not(.hide)) > span", elm).addClass("expanded").html("-");
    $("li:has(> ul.hide) > span", elm).addClass("collapsed").html("+");

    // Checkbox function
    $('input[type="checkbox"]', elm).on("click", function () {
        // If checking ...
        if ($(this).is(":checked")) {
            // Show immediate children  of checked
            $("> ul", $(this).parent("li")).removeClass("hide");

            // Update the tree
            $("> span.collapsed", $(this).parent("li")).removeClass("collapsed").addClass("expanded").html("-");

            // Check parents if necessary
            if (settings.checkParents) {
                $(this).parents("li").find('input[type="checkbox"]:first').prop("checked", true);
            }

            // Check children if necessary
            if (settings.checkChildren) {
                $(this).parent("li").find('input[type="checkbox"]').prop("checked", true);
                // Show all children of checked
                $("ul", $(this).parent("li")).removeClass("hide");
                // Update the tree
                $("span.collapsed", $(this).parent("li"))
                    .removeClass("collapsed")
                    .addClass("expanded")
                    .html("-");
            }
            // If unchecking...
        } else {
            // Uncheck children if necessary
            if (settings.uncheckChildren) {
                $(this).parent("li").find('input[type="checkbox"]').prop("checked", false);
                // Hide all children
                $("ul", $(this).parent("li")).addClass("hide");
                // Update the tree
                $("span.expanded", $(this).parent("li"))
                    .removeClass("expanded")
                    .addClass("collapsed")
                    .html("+");
            }
        }
    });

    // Tree function
    $("li:has(> ul) span", elm).on("click", function () {
        // If was previously collapsed...
        if ($(this).is(".collapsed")) {
            // ... then expand
            $("> ul", $(this).parent("li")).removeClass("hide");
            // ... and update the html
            $(this).removeClass("collapsed").addClass("expanded").html("-");

            // If was previously expanded...
        } else if ($(this).is(".expanded")) {
            // ... then collapse
            $("> ul", $(this).parent("li")).addClass("hide");
            // and update the html
            $(this).removeClass("expanded").addClass("collapsed").html("+");
        }
    });
};

export = collapsibleCheckboxTree;
