import "modules/sentry";
import "modules/redactor/index";

import collapsibleCheckboxTree from "modules/jstree";

import "./datepicker";
import "./admin.scss";

// Data attribute load
window.addEventListener("load", () => {
    document.querySelectorAll("ul.jstree").forEach((elm: HTMLElement) => {
        collapsibleCheckboxTree(elm);
    });
});
