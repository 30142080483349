import Redactor from "./redactor";

import "./_langs/en";
import "./_langs/ru";

import "./_plugins/clips/clips";
import "./_plugins/clips/clips.css";
import "./_plugins/counter/counter";
import "./_plugins/fullscreen/fullscreen";
import "./_plugins/imagemanager/imagemanager";
import "./_plugins/properties/properties";
import "./_plugins/table/table";
import "./_plugins/video/video";

import "./_scss/redactor.scss";

Redactor.addLang("ru", {
  title: "Заголовок / краткое описание",

  // alignment
  align: "Выравнивание",
  "align-left": "Выравнивание слева",
  "align-center": "Выравнивание по центру",
  "align-right": "Выравнивание справа",
  "align-justify": "Выравнивание по краям",

  // clips
  clips: "Вёрстка",
  "clips-select": "Выберите вариант для вставки",

  // counter
  words: "слов",
  chars: "знаков",

  // fullscreen
  fullscreen: "На полный экран",

  // imagemanager
  choose: "Выберите",

  // properties
  properties: "Свойства",

  // table
  table: "Таблица",
  "insert-table": "Вставить таблицу",
  "insert-row-above": "Вставить строку выше",
  "insert-row-below": "Вставить строку ниже",
  "insert-column-left": "Вставить колонку слева",
  "insert-column-right": "Вставить колонку справа",
  "add-head": "Добавить заголовок таблицы",
  "delete-head": "Удалить заголовок таблицы",
  "delete-column": "Удалить колонку",
  "delete-row": "Удалить строку",
  "delete-table": "Удалить таблицу",

  // video
  video: "Видео",
  "video-html-code": "Код для вставки видео или ссылка на Youtube/Vimeo",
});

Redactor.options = {
  lang: "ru",
  buttons: ["html", "format", "bold", "italic", "underline", "sup", "sub", "lists", "image", "link"],
  replaceTags: {
    h1: "h2",
    b: "strong",
    i: "em",
  },
  formatting: ["p", "blockquote", "h2", "h3"],
  formattingAdd: {
    lead: {
      title: "Большой абзац",
      api: "module.block.format",
      args: {
        tag: "p",
        attr: {
          class: "lead",
        },
        type: "toggle",
      },
    },
  },
  minHeight: "300px",

  imagePosition: {
    left: "figure-left",
    right: "figure-right",
  },
  imageResizable: true,
  imageObserve: false,

  autoparseHttps: true,

  linkTarget: "_blank",
  linkTitle: true,
  linkSize: 100,

  plugins: ["counter", "fullscreen", "imagemanager", "properties", "table", "video"],

  callbacks: {
    image: {
      uploaded: function (image, response) {
        let width = response.file.width;
        let height = response.file.height;
        let img = image.getElementsByTagName("img")[0];

        if (width > 0 && height > 0) {
          img.setAttribute("width", response.file.width);
          img.setAttribute("height", response.file.height);
        }
      },
      uploadError: function (response) {
        alert(response.message);
      },
    },
  },
  grammarly: false,
  pasteLinkTarget: "_blank",
  // styles: false,
  // stylesClass: 'redactor-styles',
  toolbarFixedTopOffset: 60,
  multipleUpload: false,
};

export { Redactor };
